var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"yellow-rule"}),_c('v-container',[_c('v-row',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadCrumbs,"large":""}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("title")))]),_c('Underline')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.salaries)?_c('scatter-chart',{attrs:{"height":200,"dataset":_vm.salaries,"min":1500000,"max":30000000}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-btn',{attrs:{"col":"12","color":"secondary","to":{ path: _vm.locale.base + '/submit-compensation-selection' },"dark":"","rounded":""}},[_c('v-icon',{attrs:{"left":"","dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add_compensation"))+" ")],1)],1)],1),(_vm.salariesGroupedByYear)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mb-4",attrs:{"headers":_vm.yearsOfExperienceHeaders,"items":_vm.salariesGroupedByYear,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.yearsOfExperience",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(item.yearsOfExperience)+" ")])])]}},{key:"item.salaries",fn:function(ref){
var item = ref.item;
return [_c('horizontal-scatter-chart',{attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 45 : 10,"width":_vm.$vuetify.breakpoint.mobile ? 260 : 100,"min":1500000,"max":30000000,"dataset":item.salaries,"locale":_vm.locale.code}})]}},{key:"item.firstQuartile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.firstQuartile))+" ")]}},{key:"item.median",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.median))+" ")])]}},{key:"item.thirdQuartile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.thirdQuartile))+" ")]}}],null,false,2429972165)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("call_to_add")))])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","to":{ path: _vm.locale.base + '/submit-compensation-selection' },"large":"","dark":"","rounded":""}},[_c('v-icon',{attrs:{"left":"","dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add_compensation"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }