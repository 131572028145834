<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row>
        <v-breadcrumbs :items="breadCrumbs" large></v-breadcrumbs>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1>{{ $t("title") }}</h1>
          <Underline />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <scatter-chart
            v-if="salaries"
            :height="200"
            :dataset="salaries"
            :min="1500000"
            :max="30000000"
          ></scatter-chart>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-btn
            col="12"
            color="secondary"
            :to="{ path: locale.base + '/submit-compensation-selection' }"
            dark
            rounded
          >
            <v-icon left dark large>mdi-plus</v-icon>
            {{ $t("add_compensation") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="salariesGroupedByYear">
        <v-col cols="12">
          <v-data-table
            class="mb-4"
            :headers="yearsOfExperienceHeaders"
            :items="salariesGroupedByYear"
            :loading="loading"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.yearsOfExperience="{ item }">
              <div>
                <div class="text-h6 font-weight-bold">
                  {{ item.yearsOfExperience }}
                </div>
              </div>
            </template>
            <template v-slot:item.salaries="{ item }">
              <horizontal-scatter-chart
                :height="$vuetify.breakpoint.mobile ? 45 : 10"
                :width="$vuetify.breakpoint.mobile ? 260 : 100"
                :min="1500000"
                :max="30000000"
                :dataset="item.salaries"
                :locale="locale.code"
              ></horizontal-scatter-chart>
            </template>
            <template v-slot:item.firstQuartile="{ item }">
              {{ displayCompensationItem(item.firstQuartile) }}
            </template>
            <template v-slot:item.median="{ item }">
              <div class="font-weight-bold">
                {{ displayCompensationItem(item.median) }}
              </div>
            </template>
            <template v-slot:item.thirdQuartile="{ item }">
              {{ displayCompensationItem(item.thirdQuartile) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mb-4" justify="center">
        <h2>{{ $t("call_to_add") }}</h2>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="mr-2"
          color="secondary"
          :to="{ path: locale.base + '/submit-compensation-selection' }"
          large
          dark
          rounded
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "title": "Software Engineering Salaries by Years of Experience",
    "salaries": "Home",
    "call_to_add": "Help others like you, add your own compensation!",
    "add_compensation": "Add your compensation",
    "years_of_experience": "Years of Experience",
    "annual_compensation": "Total Compensation",
    "median": "Median",
    "range": "Range",
    "count": "# of Entries"
  },
  "ja": {
    "title": "経験年数別ソフトウェアエンジニア年収",
    "salaries": "ホーム",
    "add_compensation": "年収を追加",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "years_of_experience": "経験年数",
    "annual_compensation": "年収",
    "median": "中央値",
    "range": "年収範囲",
    "count": "件"
  }
}
</i18n>

<script>
import _ from "lodash";
import store from "store";
import api from "services/api";
import Underline from "components/Underline.vue";
import ScatterChart from "components/Scatter.chart.vue";
import HorizontalScatterChart from "components/HorizontalScatter.chart.vue";
import statistics from "../utils/statistics";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  components: {
    Underline,
    ScatterChart,
    HorizontalScatterChart
  },
  data: () => ({
    salaries: null,
    salariesGroupedByYear: [],
    loading: false
  }),
  metaInfo() {
    return {
      title: this.$t("title")
    };
  },
  created() {
    this.getSalaries();
  },
  methods: {
    getSalaries(page = 1) {
      this.loading = "grey";
      const page_query = { page: page };
      const query = {
        ...page_query
      };
      api.getSalaries(query).then(
        resp => {
          this.salaries = resp.data.items;
          this.pagination = resp.data.meta;
          this.loading = false;
          this.salariesGroupedByYear = this.groupSalaries(this.salaries);
        },
        () => {
          this.loading = false;
        }
      );
    },
    groupSalaries(salaries) {
      const salariesByExperience = _(salaries)
        .groupBy("years_of_experience")
        .map((items, yearsOfExperience) => ({
          yearsOfExperience,
          salaries: _.map(items),
          median: statistics.quantile(_.map(items, "annual_compensation"), 50),
          firstQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            25
          ),
          thirdQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            75
          ),
          count: items.length
        }))
        .value();

      return salariesByExperience;
    }
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    breadCrumbs() {
      return [
        {
          text: this.$t("salaries"),
          href: this.locale.base + "/"
        },
        {
          text: this.$t("years_of_experience"),
          href: this.locale.base + "/years-of-experience"
        }
      ];
    },
    yearsOfExperienceHeaders() {
      return [
        {
          text: this.$t("years_of_experience"),
          value: "yearsOfExperience"
        },
        {
          text: this.$t("range"),
          value: "salaries",
          sortable: false
        },
        {
          text: this.$t("25%"),
          value: "firstQuartile"
        },
        {
          text: this.$t("median"),
          value: "median"
        },
        {
          text: this.$t("75%"),
          value: "thirdQuartile"
        },
        {
          text: this.$t("count"),
          value: "count"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped></style>
