<script>
import { Scatter } from "vue-chartjs";

export default {
  extends: Scatter,
  props: {
    dataset: Array,
    min: Number,
    max: Number
  },
  mounted() {
    this.renderChart(this.theData, this.options);
  },
  methods: {
    formatData(rawData) {
      const arr = rawData.map(val => {
        return {
          ...val,
          x: val.years_of_experience,
          y: val.annual_compensation
        };
      });
      return arr;
    }
  },
  data() {
    return {
      theData: {
        datasets: [
          {
            data: this.formatData(this.dataset),
            pointRadius: 4,
            borderColor: "rgba(255, 146, 51, 1)",
            fill: false
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          mode: "single",
          callbacks: {
            title: function(items, data) {
              const compensation =
                data.datasets[items[0].datasetIndex].data[items[0].index]
                  .annual_compensation /
                  1000000 +
                "M";
              return compensation;
            }
          },
          displayColors: false
        },
        scales: {
          xAxes: [
            {
              type: "linear",
              position: "bottom",
              scaleLabel: {
                display: true,
                labelString: "経験年数"
              },

              ticks: {
                stepSize: 1,
                autoSkip: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                min: this.min,
                max: this.max,
                callback: function(value) {
                  const new_value = value / 1000000 + "M";
                  return new_value;
                }
              }
            }
          ]
        }
      }
    };
  }
};
</script>
